import moment from "moment"

const dateLongPresentation = (date) => {
  return moment(date).format("MMM Do YYYY")
}

const dateParamFormat = (date) => {
  return moment(date).format("YYYY-MM-DD")
}

const formatDate = (date, format) => {
  const md = moment(date)
  return {
    "param": md.format("YYYY-MM-DD"),
    "presentationLong": md.format("MMM Do YYYY"),
    "presentationShort": md.format("MMM Do 'YY"),
    "link": md.format("/YYYY/MM/DD"),
  }[format]
}

export { dateParamFormat, dateLongPresentation, formatDate }
