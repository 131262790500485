import React from "react";
import { Route, createRoutesFromElements, createBrowserRouter } from "react-router-dom";
import wrapComponent from "~/components/ApplicationWrapper"

import Layout from "~/views/Layout"
import Dashboard from "~/views/Dashboard"

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout/>}>
      <Route path="/" index element={<Dashboard />} />
      <Route path="/:year/:month/:day" element={<Dashboard />} />
    </Route>
  )
);

export default router
