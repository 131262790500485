import React from "react"

import { BlockNoteEditor } from "@blocknote/core";
import { BlockNoteView, useBlockNote } from "@blocknote/react";
import "@blocknote/core/style.css";

const Editor = ({content, onChange, className, template}) => {
  let initialContent = content || template
  const editor = useBlockNote({
    theme: "light",
    initialContent: initialContent ? JSON.parse(initialContent) : undefined,
    onEditorContentChange: onChange,
    editorDOMAttributes: {
      class: className
    }
  });

  return <BlockNoteView editor={editor} />
}

export default Editor
