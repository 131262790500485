import React, { useMemo, useState, useEffect } from "react";
import request from "~/lib/request"
import moment from "moment"
import { formatDate } from "~/utils/dateHelpers"
import ReactMarkdown from "react-markdown"

import Button from "~/components/Button"

const Tasks = ({date}) => {
  const [tasks, setTasks] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [stagedTask, setStagedTask] = useState("")

  useEffect(() => {
    if(!date) return

    setIsLoaded(false)
    setStagedTask("")
    const req = request.get("tasks", { params: { date: formatDate(date, "param")}})
    req.then(({tasks}) => {
      setTasks(tasks)
      setIsLoaded(true)
    })
  }, [ date ])

  const submitTask = () => {
    setIsSubmitting(true)
    const req = request.post("tasks", {
      task: {
        content: stagedTask,
        initial_date: formatDate(date, "param"),
      }
    })
    req.then(({task}) => {
      const updatedTasks = tasks
      tasks.push(task)
      setStagedTask("")
      setIsSubmitting(false)
      setTasks(tasks)
    })
  }

  const completeTask = (taskId) => {
    const completed_date = formatDate(date, "param")
    const req = request.patch(`tasks/${taskId}`, { task: { completed_date }})
    req.then(({task}) => {
      const updateTasks = tasks
      setTasks(tasks.map((task) => {
        if(task.id == taskId) {
          return { ...task, completed_date: completed_date }
        }
        return task
      }))
    })
  }

  if (!isLoaded) return <p><em>Loading...</em></p>
  return(
    <div>
      <h2>Tasks</h2>
      <table className="table">
        <thead>
          <tr>
            <th />
            <th>Content</th>
            <th>Added</th>
            <th>Completed</th>
          </tr>
        </thead>
        <tbody>
          { tasks.length == 0 &&
            <tr>
              <td colSpan="4" className="text-center">
                <em>No Tasks for {date.format("MMM Do")}</em>
              </td>
            </tr>
          }
          { tasks.map((task, i) => {
            const isTaskComplete = !!task.completed_date
            return(
              <tr key={i}>
                <td style={{cursor: (isTaskComplete ? "" : "pointer" )}} onClick={() => isTaskComplete ? "" : completeTask(task.id)}>
                  <i className={`bi ${isTaskComplete ? "bi-check2-square" : "bi-square" }`} />
                </td>
                <td className={isTaskComplete ? "text-decoration-line-through" : ""}>
                  <ReactMarkdown
                    children={task.content}
                    linkTarget="_blank"
                  />
                </td>
                <td>{ formatDate(task.initial_date, "presentationShort")} </td>
                <td>{ task.completed_date ? formatDate(task.completed_date, "presentationShort") : ""} </td>
              </tr>
            )
          })}
          <tr>
            <td />
            <td colSpan="2">
              <input
                onChange={({target}) => setStagedTask(target.value)}
                value={stagedTask}
                className="form-control"
                placeholder="I want to...."
              />
            </td>
            <td>
              <Button
                size="sm"
                variant="primary"
                loading={isSubmitting}
                disabled={stagedTask == ""}
                onClick={submitTask}
              >
                Create Task
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default Tasks
