import React from "react"
import { Outlet } from "react-router-dom";

const Layout = () => {
  return(
    <div className="d-flex h-100">
      <div className="border-end" style={{width: 200}}>
      </div>
      <main id="main" className="main ps-4 flex-grow-1">
        <Outlet />
      </main>
    </div>
  )
}

export default Layout
