/* eslint no-console:0 */

import "~/stylesheets/application.scss"

import React from 'react'
import { createRoot } from "react-dom/client";
import { RouterProvider, } from "react-router-dom";

import router from '~/routes';

createRoot(document.getElementById("app")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
