import React, { useState } from "react"
import request from "~/lib/request"
import getInitialState from "~/utils/getInitialState"

import Modal from 'react-bootstrap/Modal';
import Editor from "~/components/Editor"

import Button from "~/components/Button"

const TemplateModal = ({
  isOpen,
  handleClose
}) => {

  const { user } = getInitialState()
  const [ stagedTemplate, setStagedTemplate ] = useState(user.template || undefined)

  const closeModal = () => {
    setStagedTemplate(user.template)
    handleClose()
  }

  const updateTemplate = () => {
    const req = request.patch("settings/template", { value: stagedTemplate })
    req.then(({user}) => {
      window.initialState.user = user
      handleClose()
    })
  }

  return(
    <Modal
      show={isOpen}
      onHide={closeModal}
      size="xl"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Daily Note Templae</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Editor content={stagedTemplate} onChange={(ed) => setStagedTemplate(JSON.stringify(ed.topLevelBlocks))} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          text="Cancel"
          onClick={closeModal}
        />
        <Button
          text="Update Template"
          variant="primary"
          disabled={stagedTemplate == user.template}
          onClick={updateTemplate}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default TemplateModal
