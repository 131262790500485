import React, { useMemo, useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { debounce } from "lodash"
import { formatDate } from "~/utils/dateHelpers"

import moment from "moment"
import request from "~/lib/request"

import Note from "./Dashboard/Note"
import Tasks from "./Dashboard/Tasks"
import TemplateModal from "./Dashboard/TemplateModal"

import Button from "~/components/Button"

const Dashboard = ({}) => {
  const navigate = useNavigate()
  const { year, month, day } = useParams()
  const [ currentDate, setCurrentDate ] = useState()
  const [ isToday, setIsToday ] = useState(false)
  const [ isTemplateModalOpen, setIsTemplateModalOpen ] = useState(false)

  useEffect(() => {
    if(year && month && day) {
      setCurrentDate(moment(`${year}-${month}-${day}`, "YYYY-MM-DD"))
    } else {
      setCurrentDate(moment())
    }
  }, [year, month, day])

  useEffect(() => {
    if(!currentDate) return
    setIsToday(currentDate.isSame(moment(), "day"))
  }, [ currentDate ])

  const jumpToDate = (date) => {
    const destination = date ? formatDate(date, "link") : "/"
    setCurrentDate(date || moment())
    navigate(destination, { replace: true })
  }

  if(!currentDate) return null
  return(
    <div className="d-flex flex-column p-4" style={{height: "100%"}}>
      <TemplateModal
        isOpen={isTemplateModalOpen}
        handleClose={() => setIsTemplateModalOpen(false)}
      />
      <div className="d-flex align-items-center">
        <Button
          onClick={() => jumpToDate(currentDate.subtract(1, "day"))}
          size="sm"
          variant="dark"
          text="<<"
        />
        <h2 className="mx-2">{ formatDate(currentDate, "presentationLong") }</h2>
        <Button
          onClick={() => jumpToDate(currentDate.add(1, "day"))}
          size="sm"
          variant="dark"
          text=">>"
        />
        { !isToday &&
          <Button
            onClick={() => jumpToDate(null)}
            variant="primary"
            className="ms-4"
            text="Back to Today"
          />
        }
        <Button
          onClick={() => setIsTemplateModalOpen(true)}
          variant="link"
          className="ms-auto"
          size="sm"
          text="Update Note Template"
        />
      </div>
      <Tasks date={currentDate} />
      <Note date={currentDate} />
    </div>
  )
}

export default Dashboard
