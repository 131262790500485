import React from "react"
import { Button as BSButton } from "react-bootstrap"

const Button = ({
  text,
  onClick,
  className,
  variant = "secondary",
  loading,
  children,
  ...buttonProps
}) => {
  return(
    <BSButton
      variant={variant}
      onClick={onClick}
      className={className}
      {...buttonProps}
    >
      { loading ? "Loading..." : (children || text)}
    </BSButton>
  )
}

export default Button
