import React from 'react'
import requests from "~/lib/request";

export default function wrapComponent(ComponentToWrap) {
  return class WrappedComponent extends React.Component {
    render() {
      return (
        <ApplicationWrapper>
          <ComponentToWrap {...this.props} />
        </ApplicationWrapper>
      )
    }
  }
}

export class ApplicationWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  fetchData = (url) => {
    let req = requests.get(url)
    req.then(this.setData)
  }

  setData = ({data}) => {
    this.setState({serverData: data, dataFetched: true})
  }


  render() {
    const { children } = this.props;
    const { dataFetched } = this.state

    const childrenWithProps = React.Children.map(children, child => {
      return React.cloneElement(child, Object.assign({}, this.state, {
        fetchData: this.fetchData,
        serverData: this.state.serverData,
      }))
    })
    return (
      <>
      <div className="ApplicationWrapper">
        { childrenWithProps }
      </div>
      { !dataFetched && <Spinner />}
      </>
    )
  }
}

class Spinner extends React.PureComponent {
  render() {
    return(
      <div className="absolute inset-0 right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2 w-screen h-screen">
          <div style={{borderTopColor:'transparent'}} className="border-solid animate-spin  rounded-full border-blue-400 border-8 h-2
 w-24"></div>
      </div>
    )
  }
}
