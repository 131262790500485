import React, { useMemo, useState, useEffect } from "react";
import { debounce } from "lodash"
import request from "~/lib/request"
import getInitialState from "~/utils/getInitialState"

import Editor from "~/components/Editor"

const Note = ({date}) => {
  const [ note, setNote ] = useState(null)
  const [ isLoaded, setIsLoaded ] = useState(false)

  const { user } = getInitialState()

  useEffect(() => {
    if(!date) return

    setIsLoaded(false)
    const req = request.get(`notes/${date.format("YYYY-MM-DD")}`)
    req.then(({note}) => {
      setNote(note)
      setIsLoaded(true)
    })
  }, [ date ])

  const saveContent = debounce((date, ed) => {
    request.patch(`/notes/${date.format("YYYY-MM-DD")}`, {
      note: {
        content: JSON.stringify(ed.topLevelBlocks)
      }
    })
    console.log("save", ed)
  }, 500)

  const editorCallback = (editor) => saveContent(date, editor)
  return(
    <div>
      <h2>Note</h2>
      { !isLoaded && <p><em>Loading....</em></p> }
      { isLoaded &&
        <Editor className="flex-grow-1" content={note.content} onChange={editorCallback} template={user.template} />
      }
    </div>
  )
}

export default Note
