import axios from "axios"
import { snakeCase, isObject, isArray } from "lodash"

let server = axios.create(
  {
    baseURL: "/api",
    responseType: 'json',
    headers: {
      "X-CSRF-Token": document.getElementsByName('csrf-token')[0]['content']
    }
  }
)

server.interceptors.request.use((config) => {
  if (config.data && typeof config.data === 'object') {
    config.data = transformKeys(config.data, snakeCase);
  }
  return config;
});

server.interceptors.response.use(function (response) {
  return response.data
})

function transformKeys(data, transformFn) {
  if (isArray(data)) {
    return data.map((item) => transformKeys(item, transformFn));
  } else if (isObject(data)) {
    const transformed = {};
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const transformedKey = transformFn(key);
        transformed[transformedKey] = transformKeys(data[key], transformFn);
      }
    }
    return transformed;
  } else {
    return data;
  }
}

let requests = {
  get: server.get,
  post: server.post,
  put: server.put,
  patch: server.patch,
  delete: server.delete
}

export default requests
